// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production : false,
  environments: {
    applications: { // configure desired API environment for each component application
      mdms : 'uat',
    },
    uat: {
      apiBaseUrls: {
        mdms:'http://194.163.40.250:8085/api',
        mdms0:'http://191.101.3.127:8085/api',
        mdms1: 'https://mdms-abiew.herokuapp.com/api',
        local:'https://localhost:7026/MDMS'
      }
    }
  },
  apiNames: {
    mdms: {
      login:'/login',
      adminUser:"/adminUser",
      insertCustomer:'/user',
      getAllActiveCustomers:'/GetAllActiveCustomers',
      getAllUser:'/getalluser',
      getUser:'/user',
      editUser:'/user',
      getsuspendeduser:'/getsuspendeduser',
      addrd:'/addrd',
      getrd:'/getrd',
      rd:'/rd',
      share:'/share',
      drbf:'/drbf',
      loan:'/loan',
      getallrd:'/getallrd',
      addshare : '/addshare',
      getshare : '/getshare',
      getallshare : '/getallshare',
      adddrdf : '/adddrbf',
      getdrdf : '/getdrbf',
      getalldrdf : '/getalldrbf',
      addloan:'/addloan',
      getloan:'/getloan',
      getallloan : '/getallloan',
      loanupdatestatus:'/loanupdatestatus',
      transactions:'/transactions',
      gettransactions:'/alltransactions',
      gettransactionsDetails:'/alltransactionsdetail',
      transactionsbyid:'/transactionsbyid',
      getallsettings:'/getallsettings',
      getsetting:'/getsetting',
      addsetting:'/addsetting',
      setting:'/setting',
      getallpayments:'/getallpayments',
      getpayments:'/getpayments',
      addpayments:'/addpayments',
      payments:'/payments',
      paymentsbytype:'/paymentsbytype',
      getallbank:'/getallbank',
      getbank:'/getbank',
      addbank:'/addbank',
      bank:'/bank',
      getallsuspension:'/getallsuspension',
      getsuspension:'/getsuspension',
      addsuspension:'/addsuspension',
      suspensionbystatus:'/suspensionbystatus',
      suspension:'/suspension',
      surityUsers:'/surityUsers', 
      surityuserslist:'/surityuserslist',
      getuseraccountinfo:'/getuseraccountinfo',
      getreport:'/getreport',
      getreportinactive:"/getreportinactive",
      getloanreport:'/getloanreport'
    } 
  },
  getApiUrl: function(appName: string, baseUrl?: string, apiName?: string) {
    if (apiName === undefined) {
     return this.environments[this.environments['applications'][appName]][baseUrl];
    } else {
      return this.environments[this.environments['applications'][appName]]['apiBaseUrls'][baseUrl]
      + this.apiNames[appName][apiName];
    }
  }
};