import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
  } from '@angular/common/http';
  import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorUtility implements HttpInterceptor {
    constructor(private _route:Router){}
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let token = localStorage.getItem('token');
      if (token) {
        token =token.toString().replace('"','');
        token =token.toString().replace('"','');
        httpRequest = httpRequest.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
          });
      }else{
        this._route.navigate(['login'])
      }
      return next.handle(httpRequest)
    }
}