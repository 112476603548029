
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export abstract class AbstractBaseService {

  private _headers: HttpHeaders = new HttpHeaders();
  private _params: HttpParams = new HttpParams();
  private _body: object = null;
  private _responseType = null;

  get requestHeaders(): HttpHeaders {
    return this._headers;
  }

  set requestHeaders(value: HttpHeaders) {
    this._headers = value;
  }

  get requestParams(): HttpParams {
    return this._params;
  }

  set requestParams(value: HttpParams) {
    this._params = value;
  }

  get requestBody(): any {
    return this._body;
  }

  set requestBody(value: any) {
    this._body = value;
  }

  get responseType(): string {
    return this._responseType;
  }

  set responseType(value: string) {
    this._responseType = value;
  }

  getOptions(observePropertyRequired?: boolean): object {
    const options = {};
    if (this.requestHeaders) {
      options['headers'] = this.requestHeaders;
      // this.requestHeaders = null; // Reset after use
    }

    if (this.requestParams) {
      options['params'] = this.requestParams;
      // this.requestParams = {}; // Reset after use
    }

    if (this.responseType) {
      options['responseType'] = this.responseType;
      // this.responseType = null; // Reset after use
    }
    if (observePropertyRequired) {
      options['observe'] = 'response';
    }
    return options;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
