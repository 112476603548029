import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {MaterialExampleModule} from './modules/material/material.module';
import {MatNativeDateModule} from '@angular/material/core';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { AuthGuardService as AuthGuard } from './modules/admin-layout/auth/auth-guard.service';
import { HttpInterceptorUtility } from './modules/admin-layout/utilities/http-interceptor-utility/http-interceptor-utility';
import { ErrorComponent } from './components/error/error.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    MatNativeDateModule,
    MaterialExampleModule,
    ToastrModule.forRoot(),
    
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent
  ],
  providers: [AuthGuard,{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
  {provide:HTTP_INTERCEPTORS,useClass:HttpInterceptorUtility,multi:true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
