<div class="container mt-5">

    <div class="row">
        <div class="col-6 offset-3 login">

            <h1 class="text-title">Welcome to MDMS </h1>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }" />
                    <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                        <div *ngIf="f.Email.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Password">Password</label>
                    <input type="password" formControlName="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" />
                    <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                        <div *ngIf="f.Password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>
                    <a routerLink="/register" class="btn btn-link">Register</a>
                </div>
            </form>

        </div>

</div>

</div>
