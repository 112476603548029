import { Component, OnInit} from '@angular/core';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  appLoaded = false;
  constructor(private readonly _router: Router) {}
  ngOnInit() {
    this._router.events.subscribe((evt: Event) => {
      this.navigationInterceptor(evt);
    });
    if (window.location.href.includes('error')) {
      this._router.navigate(['./'])
    }
    // let time = new Date().getTime();
    // const setActivityTime = (e) => {
    //   time = new Date().getTime();
    // }
    // document.body.addEventListener("mousemove", setActivityTime);
    // document.body.addEventListener("keypress", setActivityTime);
    // const refresh = () => {
    //   if (new Date().getTime() - time >= 600000) {
    //     window.location.reload();
    //   } else {
    //     setTimeout(refresh, 10000);
    //   }
    // }
    // setTimeout(refresh, 10000);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: Event): void {
    switch (true) {
      case event instanceof NavigationStart: {
          this.appLoaded = false;
        break;
      }
      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.appLoaded = true;
        break;
      }
      default:
        break;
    }
  }
}
